import React from 'react'

import { BsPatchCheckFill, BsStars, BsWhatsapp } from 'react-icons/bs'

interface IParametros {
    titulo: string
    descricao: string
    lista: string[]
    conclusao?: string
    midia: string
    layout: number
    venda?: string
    avaliacao?: {
        foto: string
        nome: string
        empresa: string
        comentario: string
    } | null
}

export default function Sessao({ titulo, descricao, lista, conclusao, midia, layout, venda, avaliacao }: IParametros) {
    return (
        <div>
            <section className={'my-12 flex items-center flex-col-reverse ' + (layout === 0 ? 'md:flex-row-reverse' : 'md:flex-row')}>
                <div className="flex-1 text-gray-900 text-justify">
                    <h2 className="text-xl font-bold">{titulo}</h2>
                    <p className="mt-1 mb-4 text-gray-600 text-justify">{descricao}</p>

                    {lista.map((item, i) => (
                        <div key={i} className="flex items-center my-1">
                            <BsPatchCheckFill className="text-2xl text-emerald-500" />
                            <p className="flex-1 ml-2">{item}</p>
                        </div>
                    ))}

                    {avaliacao && (
                        <div className="bg-slate-100 rounded-xl p-5 mt-5">
                            <div className="flex items-center ">
                                <img className="w-12 h-12 rounded-full border-2 border-slate-600 mr-2" src={avaliacao.foto} alt={avaliacao.nome} />
                                <div className="flex-1">
                                    <p className="text-xl leading-4 font-bold">{avaliacao.nome}</p>
                                    <p className="text-sm italic text-gray-600">{avaliacao.empresa}</p>
                                </div>
                                <BsStars className="text-2xl text-yellow-400" />
                            </div>
                            <p className="text-justify mt-2 text-sm">{avaliacao.comentario}</p>
                        </div>
                    )}

                    {!!conclusao && (
                        <p className="bg-emerald-50 border-2 border-emerald-600 rounded-lg p-2 text-center text-emerald-600 mt-5">{conclusao}</p>
                    )}
                </div>

                <div className="flex-1 mb-5 md:mb-0">
                    <img className="w-full h-96" src={midia} alt="modulo x" />
                </div>
            </section>

            {!!venda && (
                <div className="bg-slate-100 rounded-xl flex flex-col md:flex-row items-center justify-between p-5">
                    <p className="text-gray-600 flex-1 text-center md:text-left">{venda}</p>
                    <a
                        className="bg-emerald-500 border-2 border-emerald-600 hover:bg-emerald-600 text-white rounded-lg p-2 text-center flex items-center justify-center transition duration-500 mt-5 md:mt-0 md:ml-5"
                        href="https://bit.ly/3iYeQJT"
                        target="_blank"
                        rel="noreferrer">
                        Mandar mensagem
                        <BsWhatsapp className="text-lg ml-2" />
                    </a>
                </div>
            )}
        </div>
    )
}
