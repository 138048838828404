import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import './index.css'

import { Cabecalho, Rodape } from './components/Layout'

import Inicio from './pages/Inicio'
import Loja from './pages/Loja'
import Industria from './pages/Industria'
import { VolteParaTopo } from './components/Topo'

function App() {
    return (
        <Router>
            <Cabecalho />

            <Routes>
                <Route path="/" element={<Inicio />} />
                <Route path="/loja" element={<Loja />} />
                <Route path="/industria" element={<Industria />} />
            </Routes>

            <Rodape />

            <VolteParaTopo />
        </Router>
    )
}

export default App
